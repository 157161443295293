@import-normalize; /* bring in normalize.css styles */
@import 'styles/fonts.css';

html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

*, *:before, *:after {
  box-sizing: inherit;
}